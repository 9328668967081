import request from '../../utils/request'
// const token=localStorage.getItem("access_token");
// console.log('token',token)

//根据查询条件获取房间模板列表
export function TemplateList(hotelId, templateName, limit,page,token) {
    return request({
        url:"/hotel/template/getTemplatePageList",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            hotelId:hotelId,
            templateName:templateName,
            limit:limit,
            page:page,
        }
    })
}

//新增模板
export function addTemplate(hotelId,templateName,voiceType,shopId,remarks,token){
    return request({
        url:"/hotel/template/addTemplate",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            hotelId:hotelId,
            templateName:templateName,
            voiceType:voiceType,
            shopId:shopId,
            remarks:remarks
        }
    })
}
//修改模板
export function updateTemplate(templateId,templateName,voiceType,shopId,remarks,token) {
    return request({
        url:"/hotel/template/updateTemplate",
        method:"post",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            templateId:templateId,
            templateName:templateName,
            voiceType:voiceType,
            shopId:shopId,
            remarks:remarks
        }
    })
}

//删除酒店
export function delRoom(templateId,token){
    return request({
        url:"/hotel/template/deleteTemplate",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            templateId:templateId
        }
    })
}
//查询红外码库
export function codeList(hotelId,productType,token) {
    // const token=localStorage.getItem("access_token");
    console.log('token',token)
    return request({
        url:"/hotel/getIrCodeLibrary",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            hotelId:hotelId,
            productType:productType,
        }
    })
}

//新增编辑红外码库
export function addCode(data,token){
    console.log('data',data)
    return request({
        url:"/hotel/saveOrUpdateIrCodeLibrary",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        data:data
    })
}
